import github from "../images/github.png";
import linkedin from "../images/linkedin.png";
import gmail from "../images/gmail.png";

const ConnectBlock = () => {
  return (
    <div className="Block Connect">
      <div id="connect" className="InnerBlock">
        <div className="Header L Blue Center">Connect</div>
        <div className="FlexRow Logos">
          <div className="FlexBox">
            <a href="https://www.github.com/mattw65" target="_blank" rel="noopener noreferrer">
                <div className="ClickBox Github" style={{backgroundImage:`url(${github})`}}/>
            </a>
          </div>
          <div className="FlexBox">
          <a href="mailto:mattw6500@gmail.com">
                <div className="ClickBox Gmail" style={{backgroundImage:`url(${gmail})`}}/>
            </a>
          </div>
          <div className="FlexBox">
          <a href="https://www.linkedin.com/in/mattw65" target="_blank" rel="noopener noreferrer">
                <div className="ClickBox LinkedIn" style={{backgroundImage:`url(${linkedin})`}}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectBlock;
