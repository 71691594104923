import { useState } from "react";
import mountains from "../images/mountains.png";
import "./components.scss";

const ChainLink = (props: { name: string; href: string; color: string }) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="LinkLine">
      <a
        className="Header M"
        href={`#${props.href}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          color: hover ? "#fabd05" : props.color,
          paddingRight: ".5vw",
        }}
      >
        {props.name}
      </a>
      <div
        className="ChainLink"
        style={{
          borderColor: hover ? "#fabd05" : props.color,
        }}
      />
    </div>
  );
};

const LinkBlock = (props: { color: string }) => {
  return (
    <div className="LinkLine">
      <div className="LinkLineInner"
        style={{
          background: props.color,
          zIndex: "1",
        }}
      ></div>
    </div>
  );
};

const IntroBlock = (props: { textColor: string; bgColor: string }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        className="Block Sky"
        style={{
          background: props.bgColor,
        }}
      >
        <div
          className="InnerBlock Intro"
          style={{
            display: "inline-block",
            color: props.textColor,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="IntroLeft">
              <div className="Header L">Matt Walsh</div>
              <div className="Header M">Software Engineer</div>
              <div className="Header M">New York, NY</div>
            </div>
            <div className="IntroRight">
              <ul className="Links">
                <li>
                  <ChainLink
                    name="Resume"
                    href="resume"
                    color={props.textColor}
                  />
                </li>
                <li>
                  <LinkBlock color={props.textColor} />
                </li>
                <li>
                  <ChainLink
                    name="Education"
                    href="school"
                    color={props.textColor}
                  />
                </li>
                <li>
                  <LinkBlock color={props.textColor} />
                </li>
                <li>
                  <ChainLink
                    name="Projects"
                    href="projects"
                    color={props.textColor}
                  />
                </li>
                {/* <li>
                  <LinkBlock color={props.textColor} />
                </li>
                <li>
                  <Link
                    name="Adventures"
                    href="resume"
                    color={props.textColor}
                  />
                </li> */}
                <li>
                  <LinkBlock color={props.textColor} />
                </li>
                <li>
                  <ChainLink
                    name="Connect"
                    href="connect"
                    color={props.textColor}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="Mountains"
        style={{
          backgroundImage: `url(${mountains})`, 
        height: window.outerHeight > 800 ? "240px" : "30vh",
        top: window.outerHeight > 800 ? "560px" : "70vh",
        }}
      ></div>
    </div>
  );
};

export default IntroBlock;
