import { useEffect, useState } from "react";
import ground from "../images/ground.png";
import blueWizard from "../images/blue_wizard.png";
import blueWizard2 from "../images/blue_wizard2.png";
import blueWizard3 from "../images/blue_wizard3.png";
import redWizard from "../images/red_wizard.png";
import redWizard2 from "../images/red_wizard2.png";
import redWizard3 from "../images/red_wizard3.png";
import { useTransition, animated } from "@react-spring/web";
import { useSpringRef } from "react-spring";
import { projectList } from "../data/ProjectList";
import "./components.scss";
import { ClientRequest } from "http";

const Wizard = (props: { side: "left" | "right"; onClick: () => void }) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 500);
    props.onClick();
  };
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position: "absolute",
        bottom: "14vh",
        cursor: "pointer",
        right: props.side === "right" ? "15vh" : "",
        left: props.side === "left" ? "15vh" : "",
        zIndex: 1,
      }}
    >
      <div
        onClick={handleClick}
        style={{
          backgroundImage: clicked
            ? props.side === "right"
              ? `url(${blueWizard3})`
              : `url(${redWizard3})`
            : !hover
            ? props.side === "right"
              ? `url(${blueWizard})`
              : `url(${redWizard})`
            : props.side === "right"
            ? `url(${blueWizard2})`
            : `url(${redWizard2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: 132,
          width: !clicked ? 89 : 101
        }}
      />
      <div
        style={{
          padding: "1vh",
          paddingTop: "2vh",
          fontFamily: "Pixelated",
          fontSize: "2vh",
          color: !hover ? "white" : "#f2ac29",
          textAlign: props.side === "right" ? "right" : "left",
        }}
      >
        {props.side === "right" ? "Next" : "Back"}
      </div>
    </div>
  );
};

const ProjectsBlock = () => {
  const [index, set] = useState(0);
  const onClickNext = () => {
    set((state) => (state + 3) % projectList.length);
  };
  const onClickBack = () => {
    set((state) => (state - 3 + projectList.length) % projectList.length);
  };
  const transRef = useSpringRef();
  const transitionsL = useTransition(index, {
    ref: transRef,
    keys: null,
    config: { mass: 10, tension: 500, friction: 100 },
    from: {
      opacity: 0,
      transform: "perspective(500px) translate3d(0%,0,0) rotateZ(0deg)",
      scale: 0,
    },
    enter: {
      opacity: 1,
      transform: "perspective(500px) translate3d(0%,0,0) rotateZ(0deg)",
      scale: 1,
    },
    leave: {
      opacity: 0,
      transform: "perspective(500px) translate3d(-100%,0,0) rotateZ(-180deg)",
      scale: 0,
    },
  });
  const transitionsM = useTransition(index, {
    ref: transRef,
    keys: null,
    config: { mass: 10, tension: 500, friction: 100 },
    from: {
      opacity: 0,
      transform: "perspective(500px) translate3d(0%,0,0) rotateX(0deg)",
      scale: 0,
    },
    enter: {
      opacity: 1,
      transform: "perspective(500px) translate3d(0%,0,0) rotateX(0deg)",
      scale: 1,
    },
    leave: {
      opacity: 0,
      transform: "perspective(500px) translate3d(0%,0,0) rotateX(180deg)",
      scale: 0,
    },
  });
  const transitionsR = useTransition(index, {
    ref: transRef,
    keys: null,
    config: { mass: 10, tension: 500, friction: 100 },
    from: {
      opacity: 0,
      transform: "perspective(500px) translate3d(0%,0,0) rotateZ(0deg)",
      scale: 0,
    },
    enter: {
      opacity: 1,
      transform: "perspective(500px) translate3d(0%,0,0) rotateZ(0deg)",
      scale: 1,
    },
    leave: {
      opacity: 0,
      transform: "perspective(500px) translate3d(100%,0,0) rotateZ(180deg)",
      scale: 0,
    },
  });

  useEffect(() => {
    transRef.start();
  }, [index]);

  return (
    <div
      className="Block Projects"
      style={{
        maxHeight: 800,
        minWidth: 1000,
      }}
    >
      <div
        id="projects"
        style={{
          height: "90vh",
          width: "100%",
          paddingTop: "5vh",
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(180deg, rgb(100, 195, 250) 0%, rgb(165, 181, 190) 100%)",
        }}
      >
          <div className="Header L White Center">Projects</div>
          <div
            style={{
              height: "80vh",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="ProjectBoxesOuter"
            >
              <animated.div className="flex fill ProjectBox L">
                {transitionsL((style, i) => {
                  const Page = projectList[i];
                  return <Page style={style} />;
                })}
              </animated.div>
              <animated.div className="flex fill ProjectBox M">
                {transitionsM((style, i) => {
                  const Page = projectList[i + 1];
                  return <Page style={style} />;
                })}
              </animated.div>
              <animated.div className="flex fill ProjectBox R">
                {transitionsR((style, i) => {
                  const Page = projectList[i + 2];
                  return <Page style={style} />;
                })}
              </animated.div>
            </div>
          </div>
          <Wizard onClick={onClickNext} side="right" />
          <Wizard onClick={onClickBack} side="left" />
          <div
            style={{
              backgroundColor: "rgb(50, 17, 11)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "20vh",
              zIndex: 0,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${ground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "10vh",
                zIndex: 0,
              }}
            />
          </div>
        </div>
      </div>
  );
};

export default ProjectsBlock;
