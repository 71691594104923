import { useEffect, useState } from "react";
import ResumeBlock from "components/ResumeBlock";
import SchoolBlock from "components/EducationBlock";
import ConnectBlock from "components/ConnectBlock";
import IntroBlock from "components/IntroBlock";
import ProjectsBlock from "components/ProjectsBlock";
import "./App.scss";

function App() {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = 2200;

      const progress = scrollPosition / (documentHeight - windowHeight);
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getSkyColor = () => {
    const lightblue = [100, 195, 250];
    const darkblue = [10, 60, 89];
    const lightorange = [165, 181, 190];
    const darkorange = [235, 58, 5];

    const bluemap = lightblue.map((channel, index) => {
      const targetChannel = darkblue[index];
      const currentChannel =
        channel + (targetChannel - channel) * scrollProgress;
      return Math.round(currentChannel);
    });

    const orangemap = lightorange.map((channel, index) => {
      const targetChannel = darkorange[index];
      const currentChannel =
        channel + (targetChannel - channel) * scrollProgress;
      return Math.round(currentChannel);
    });

    return `linear-gradient(180deg, rgb(${bluemap[0]}, ${bluemap[1]}, ${bluemap[2]}) 0%, rgb(${orangemap[0]}, ${orangemap[1]}, ${orangemap[2]}) 100%)`;
  };

  const getSunColor = () => {
    const light = [255, 255, 255];
    const dark = [237, 156, 33];

    const color = light.map((channel, index) => {
      const targetChannel = dark[index];
      const currentChannel =
        channel + ((targetChannel - channel) * scrollProgress) / 0.7;
      return Math.round(currentChannel);
    });

    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  return (
    <div>
      <IntroBlock bgColor={getSkyColor()} textColor={getSunColor()} />
      <ResumeBlock />
      <SchoolBlock />
      <ProjectsBlock />
      <ConnectBlock />
    </div>
  );
}

export default App;
