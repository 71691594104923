import { CSSProperties } from "react";
import { AnimatedProps, animated } from "react-spring";
import cff from "../data/projects/cff.png";
import ships from "../data/projects/ships.png";
import lanes from "../data/projects/lanes.png";
import phish from "../data/projects/phish.png";
import qisa from "../data/projects/qisa.png"
import cyber from "../data/projects/cyber.png"

export const projectList: ((
  props: AnimatedProps<{ style: CSSProperties }>
) => React.ReactElement)[] = [
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <animated.div style={{ height: "25%" }}>
        {"Predicting Ship Arrivals with SVR and LSTM"}
      </animated.div>
      <animated.a href={`projects/ships.pdf`}>
        <animated.div
          style={{
            backgroundImage: `url(${ships})`,
            backgroundSize: "cover",
            height: "60%",
          }}
        />
      </animated.a>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <animated.div style={{ height: "25%" }}>
        {"Data Reconstruction for Autonomous Lane Detection"}
      </animated.div>
      <animated.a href={`projects/lanes.pdf`}>
        <animated.div
          style={{
            backgroundImage: `url(${lanes})`,
            backgroundSize: "cover",
            height: "60%",
          }}
        />
      </animated.a>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <animated.div style={{ height: "25%" }}>
        {"Semantic Graph Analysis for Phishing Detection"}
      </animated.div>
      <animated.a href={`projects/phish.pdf`}>
        <animated.div
          style={{
            backgroundImage: `url(${phish})`,
            backgroundSize: "cover",
            height: "60%",
          }}
        />
      </animated.a>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <animated.div style={{ height: "25%" }}>
        {"Extracting Compton Form Factors with Neural Networks"}
      </animated.div>
      <animated.a href={`projects/cff.pdf`}>
        <animated.div
          style={{
            backgroundImage: `url(${cff})`,
            backgroundSize: "cover",
            height: "60%",
          }}
        />
      </animated.a>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <animated.div style={{ height: "25%" }}>
        {"Qualitative Evaluation of Quantum Instruction Set Architectures"}
      </animated.div>
      <animated.a href={`projects/qisa.pdf`}>
        <animated.div
          style={{
            backgroundImage: `url(${qisa})`,
            backgroundSize: "cover",
            height: "60%",
          }}
        />
      </animated.a>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <animated.div style={{ height: "25%" }}>
        {"Analyzing Cyber Security in the Age of Remote Work"}
      </animated.div>
      <animated.a href={`projects/cyber.pdf`}>
        <animated.div
          style={{
            backgroundImage: `url(${cyber})`,
            backgroundSize: "cover",
            height: "60%",
          }}
        />
      </animated.a>
    </animated.div>
  ),
  //   ({ style }) => (
  //     <Project
  //       label="Detecting Misinformation Networks with GNNs"
  //       pdf="cff.pdf"
  //       png={CFF}
  //     />
  //   ),

  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>

  //     </animated.div>
  //   ),
  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>

  //     </animated.div>
  //   ),
  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>Lecture on ZK-SNARKs</animated.div>
  //   ),
  //   ({ style }) => (
  //

  //     </animated.div>
  //   ),
  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>

  //     </animated.div>
  //   ),
  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>
  //       Qualitative Evaluation of Quantum Instruction Set Architectures
  //     </animated.div>
  //   ),
  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>
  //       Analyzing Cyber Risk with Employee Surveillance
  //     </animated.div>
  //   ),
  //   ({ style }) => (
  //     <animated.div style={{ ...style }}>
  //       Autonomous Racetrack Navigation
  //     </animated.div>
  //   ),
];
