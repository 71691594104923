import resume from "../data/resume.json";
const ResumeBlock = () => {
  return (
    <div className="Block Work">
      <div id="resume" className="InnerBlock Resume">
        <div className="Header Title L Center">Work Experience</div>
        {resume["Work Experience"].map((j) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingBottom: "20px",
            }}
          >
            <div className="VerticalBox">
              <div className="DatesBlock">
                {j.EndDate ? (
                  <div className="TopDate">
                    <div className="Header LightBlue S Right">
                      {j.EndDate.substring(0, 2)}
                    </div>
                    <div className="Header LightBlue S Right">
                      {`'${j.EndDate.substring(3)}`}
                    </div>
                  </div>
                ) : null}
                <div className="BottomDate">
                  <div className="Header LightBlue S Right">
                    {j.StartDate.substring(0, 2)}
                  </div>
                  <div className="Header LightBlue S Right">
                    {`'${j.StartDate.substring(3)}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="VerticalBox">
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: "2vw",
                  height: ".5vw",
                  backgroundColor: "#2f528f",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  width: ".5vw",
                  backgroundColor: "#2f528f",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "2vw",
                  height: ".5vw",
                  backgroundColor: "#2f528f",
                }}
              />
            </div>
            <div style={{ flex: 1, paddingTop: 10 }}>
              {/* Row with Title */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="Header ML Gold">{j.Title}</div>
              </div>
              {/* Row with Company and Location */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, paddingBottom: "20px" }}>
                  <div className="Header M Yellow">{j.Company}</div>
                </div>
                <div style={{ flex: 1, paddingBottom: "20px" }}>
                  <div className="Header M Right Yellow">{j.Location}</div>
                </div>
              </div>
              {/* Row with Blurb */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <div className="Text">{j.Blurb}</div>
                </div>
              </div>
              {/* Row with Bullets */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="Text">
                  <ul>
                    {j.Bullets.map((b) => (
                      <li>{b}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="VerticalBox"/>
            <div className="VerticalBox"/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResumeBlock;
