import resume from "../data/resume.json";
import uva from "../images/uva.png";
import "./components.scss";

const SchoolBlock = () => {
  return (
    <div className="Block School">
      <div id="school" className="InnerBlock">
        <div className="Header Title L Blue Center">Education</div>
        <table
          style={{
            width: "100%",
          }}
        >
          <tr>
            <td
              style={{
                width: "80%",
              }}
            >
              {resume["Education"].map((j) => (
                <div style={{paddingBottom: 30}}>
                  <div className="Header ML Gold">{j.Major}</div>
                  <div className="Header M LightBlue">{j.Degree}</div>
                  <div className="Header S Blue">
                    {j.School.split(", ")[0]}
                    <br></br>
                    {j.School.split(", ")[1]}
                  </div>
                </div>
              ))}
            </td>
            <td
              style={{
                width: "40%",
              }}
            >
              <div
                className="UvaLogo"
                style={{
                  backgroundImage: `url(${uva})`,
                }}
              ></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default SchoolBlock;
